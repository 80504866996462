<template>
  <div class="stato-stazione-container">
    <alert
      :message="message"
      :modalActive="modalActive"
      :isError="status !== 'success'"
      @close="closeNotification"
    />
    <ChangeNameRh
      :isOpen="isShowModal"
      @close="toggleModal"
      :data="selectedData"
      title="Change Name"
    />
    <sidebar :noSocial="true" :backOn="true" />
    <div class="device-container">
      <deviceCard :id="props.id" />
      <div class="xs-icon-card">
        <img src="@/assets/umiditterreno.png" />
        <p>{{ $t("soilMoisture") }}</p>
      </div>
    </div>
    <div class="content">
      <IdroTitle :title="title" />
      <div class="main">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <span></span>
                </th>
                <th>
                  <span>{{ $t("level") }} 1</span>
                </th>
                <th>
                  <span>{{ $t("level") }} 2</span>
                </th>
                <th>
                  <span>{{ $t("level") }} 3</span>
                </th>
                <th>
                  <span>{{ $t("level") }} 4</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Coordinate</span>
                </th>
                <th>
                  <span>{{ $t("action") }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="skeletonLoading">
                <td>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-4 bg-gray-200 rounded-full dark:bg-gray-800 w-full"
                    ></div>
                  </div>
                </td>
                <td>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-4 bg-gray-200 rounded-full dark:bg-gray-800 w-full"
                    ></div>
                  </div>
                </td>
                <td>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-4 bg-gray-200 rounded-full dark:bg-gray-800 w-full"
                    ></div>
                  </div>
                </td>
                <td>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-4 bg-gray-200 rounded-full dark:bg-gray-800 w-full"
                    ></div>
                  </div>
                </td>
                <td>
                  <div role="status" class="max-w-sm animate-pulse">
                    <div
                      class="h-4 bg-gray-200 rounded-full dark:bg-gray-800 w-full"
                    ></div>
                  </div>
                </td>
              </tr>
              <tr v-for="(tData, index) in rhData" :key="tData">
                <td>
                  <p>RH {{ index + 1 }}</p>
                </td>
                <td>
                  <p>{{ tData.level.split(",")[0] }}</p>
                </td>
                <td>
                  <p>{{ tData.level.split(",")[1] }}</p>
                </td>
                <td>
                  <p>{{ tData.level.split(",")[2] }}</p>
                </td>
                <td>
                  <p>{{ tData.level.split(",")[3] }}</p>
                </td>
                <td>
                  <p>{{ tData.level.split(",")[4] }}</p>
                </td>
                <td>
                  <p>{{ tData.latitude + " , " + tData.longitude }}</p>
                </td>
                <td>
                  <div style="display: flex">
                    <BaseButton
                      type="button"
                      class="filled__blue mr-2"
                      :label="isMarked[index] ? 'Marked!' : 'Set Marker'"
                      @click="postRhData(tData, index)"
                    />
                    <BaseButton
                      type="button"
                      class="filled__blue"
                      label="Change Name"
                      @click="
                        toggleModal();
                        setSelectedData(tData);
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDevicesStore } from "@/stores/DevicesStore";
import {
  defineAsyncComponent,
  computed,
  onMounted,
  ref,
  onBeforeMount,
  onUnmounted,
} from "@vue/runtime-core";
import BaseButton from "@/components/button/BaseButton.vue";
import ChangeNameRh from "@/components/modal/sensor/ChangeNameRh.vue";

//props
const props = defineProps({
  id: String,
});

//asynchronus component
const deviceCard = defineAsyncComponent(() =>
  import("@/components/cards/deviceCardSmall.vue")
);
//state
const devicesStore = useDevicesStore();
const newData = computed(() => {
  return [devicesStore.deviceData];
});

const title = ref();
const skeletonLoading = ref(true);
const rhDataList = ref([]);
const latitude = ref("");
const longitude = ref("");
const message = ref("");
const status = ref("");
const rhData = ref([]);
const isMarked = ref([]);
const modalActive = ref(false);
const selectedData = ref({});
let MAX_PROGRAM_NUMBER = 30;
const isShowModal = ref(false);

function toggleModal() {
  isShowModal.value = !isShowModal.value;
}

function setSelectedData(data) {
  selectedData.value = {
    name: data.name,
    device_code: devicesStore.deviceData.code,
  };
}

function getLocation() {
  navigator.geolocation.getCurrentPosition(showPosition);
}

function showPosition(position) {
  latitude.value = position.coords.latitude;
  longitude.value = position.coords.longitude;
}

async function getRhDataList() {
  rhDataList.value = [];
  await devicesStore.getDeviceRh(devicesStore.deviceData.code);
  if (devicesStore.deviceRhData.length !== 0) {
    for (let i = 0; i < MAX_PROGRAM_NUMBER; i++) {
      rhDataList.value.push(devicesStore.deviceRhData[i]);
    }
  }
}

function getRhData() {
  rhData.value = [];
  for (let i = 0; i < MAX_PROGRAM_NUMBER; i++) {
    rhData.value.push(rhDataList.value[i]);
  }
}

async function postRhData(data, index) {
  const payload = {
    name: data.name,
    latitude: latitude.value.toString(),
    longitude: longitude.value.toString(),
    device_code: devicesStore.deviceData.code,
  };
  const response = await devicesStore.postDeviceRh(payload);

  status.value = response.status;

  if (response.status === "success") {
    message.value = "RH marked success!";
    isMarked.value[index] = true;
  } else {
    message.value = "RH marking failed!";
  }

  modalActive.value = true;
  setTimeout(closeNotification, 3000);
}

const closeNotification = () => {
  modalActive.value = false;
};

async function getLastData() {
  console.log("fetching new data");
  await getRhDataList();
  getRhData();
}

let getLastDataInterval = null;

onBeforeMount(async () => {
  await devicesStore.loadDevice(props.id);
  title.value = "Idrosat:" + devicesStore.deviceData.name;
  getLastData();
  getLocation();
  isMarked.value = rhData.value.map(() => false);
  skeletonLoading.value = false;
});

onMounted(() => {
  getLastDataInterval = setInterval(getLastData, 5000);
});

onUnmounted(() => {
  clearInterval(getLastDataInterval);
});
</script>

<style scoped>
.stato-stazione-container {
  @apply relative flex flex-col 
      px-[16px] md:px-[200px] lg:px-[260px] xl:px-[320px] 2xl:px-[360px];
}
.main {
  @apply flex flex-col w-full justify-center items-center relative;
}

.device-container {
  @apply flex sm:flex-col fixed items-end sm:items-center gap-2
      bottom-0 left-4
      pb-4 sm:pb-8;
}

.content {
  @apply flex flex-col justify-center
      gap-2
      w-full
      sm:gap-4
      sm:my-[20px];
}

input[type="text"],
input[type="password"],
input[type="number"] {
  @apply pl-2 rounded w-10 md:w-20 py-2;
}

.button-wrapper {
  @apply mt-3 flex w-[100px] mx-auto;
}

/* Table Style */
.table-container {
  @apply w-full
      relative
      h-[300px] sm:h-full
      overflow-auto sm:overflow-visible;
}
.table-container table {
  @apply mb-10 w-full;
}

.table-container th {
  @apply font-semibold py-[10px] px-[10px] text-left sticky top-0 
      border-b-2 border-[#3a3a3e] bg-[#DDE8FA]/60 backdrop-blur-lg z-10;
}
.table-container td {
  @apply py-[10px] px-[10px]
      bg-[#DDE8FA]/60 backdrop-blur-lg;
}
.table-container th,
td {
  @apply text-[10px] md:text-[16px];
}

.table-container tr {
  @apply text-left;
}
</style>
